.Resume-div-main-parent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--primary-color-second);
}

.Resume-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    overflow: hidden;

}

.Resume-title-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content: left;
}


.Resume-profile-Description-main {
    color: white;
    display: flex;
    flex-direction: column;
}

.Resume-Description-Name {
    font-size: 3rem;
    font-family: var(--primary-font)
}

.Resume-Description-Post {
    font-size: 1rem;
    font-family: var(--secondary-font);
    white-space: nowrap;
    color: #53cfd8;
}

.Resume-Description-About {
    padding-top: 5px;
    font-family: var(--secondary-font);
    font-size: 0.8rem;
}

.Resume-div-divideLine {
    width: 90vw;
    height: 0.5px;
    opacity: 0.3;
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 40px;
    margin-bottom: 40px;
}

.Resume-div-details-title-text {
    font-size: 1rem;
    font-family: var(--secondary-font);
    white-space: nowrap;
    color: #53cfd8;
}

.Resume-div-second-container-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Resume-div-second-container-1 {
    /* width: 40vw; */
    /* outline: 1px solid rebeccapurple; */
    /* justify-content: space-around; */
}

.Resume-div-second-container-2 {}

.Resume-div-second-container-3 {
    width: 40vw;
}

.Resume-div-second-container-title {
    /* font-family: Raleway-Bold; */
    font-family: var(--secondary-font);
    color: #53cfd8;
    padding-bottom: 40px;
}

.Resume-div-second-container-1-description {
    color: black;
}

.Resume-div-second-container-1-contact-div-contact-info {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
}

.Resume-div-second-container-1-contact-div-contact-info-icon {
    width: 25px;
    height: 25px;
}

.Resume-div-second-container-1-contact-div-contact-info-detail {
    padding-left: 0.5vw;
    color: white;
    font-family: var(--secondary-font);
    display: flex;
    align-items: center;
    font-size: 0.8rem
}

.Resume-div-work-experience-content {
    display: flex;
    flex-direction: row;
    /* width: auto; */
}

.Resume-div-dot {
    margin-top: 3vh;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 14px solid #ffffff;
    border-bottom: 7px solid transparent;
    margin-right: 1vw;
    background-color: #53cfd8;
    border-radius: 50%;
}

.Resume-div-details-title {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    color: white;
    font-size: 0.9rem;
    font-family: var(--secondary-font);
}

.Resume-div-details-date {
    padding-top: 1px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.573);
    font-family: var(--secondary-font);
}

.Resume-div-details-description {
    padding-top: 1px;
    color: rgba(255, 255, 255, 0.345);
    font-family: var(--secondary-font);
    font-size: 0.7rem;
    /* padding-right: 10vw; */
}

@media (max-width: 750px) {

    .Resume-title-div {
        flex-direction: column;
        align-items: center;
    }

    .Resume-profile-Description-main {
        padding-top: 3vh;
        padding-left: 0vw;
        padding-right: 0vw;
        align-items: center;
        text-align: center;
    }

    .Resume-div-second-container-1 {
        width: 70vw;
    }

    .Resume-div-second-container-2 {}

    .Resume-div-second-container-3 {
        width: 70vw;
    }

    .Resume-div-second-container-main {
        display: flex;
        flex-direction: column;
    }

}