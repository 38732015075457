.footer_parent {
    width: 100vw;
    text-align: center;
    font-family: var(--secondary-font);
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.footer_parent:hover {
    cursor: pointer;
}

.footer_parent,
.portfolio_source_code {
    padding-left: 50px;
    padding-right: 50px;
}

.portfolio_old_version {
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: underline;
}

@media (max-width:600px) {
    .footer_parent {
        display: flex;
    }

    .footer_parent,
    .portfolio_source_code {
        padding-left: 7px;
        padding-right: 7px;
    }
}