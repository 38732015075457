.home_page_main {
    display: flex;
    width: 100vw;
    /* padding-left: 5vw; */
    /* padding-right: 5vw; */
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
}

.home_page_left {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 50%;
    padding-left: 5vw;
}

.home_page_right {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 50%;
    padding-right: 5vw;
}

.home_buttons_parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-left: 1vw;
}

.home_button {
    border: 2px solid var(--secondary-color);
    padding: 10px;
    border-radius: 50%;
    overflow: visible;
    transition: 0.3s;
    color: var(--secondary-color);
}


.contact_me_button:hover{
    cursor: pointer;
}

.home_button:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.3s ease;
    border-color: var(--primary-color);
    color: var(--primary-color);
    transition: 0.3s;
}


.home_page_name {
    font-family: Raleway-ExtraBold;
    color: var(--text-color-primary);
    font-size: 6rem;
}

.home_page_description_h1 {
    font-family: var(--secondary-font);
    font-weight: 100;
    color: var(--text-color-primary);
    font-size: 2rem;
}

.home_page_description_h2,
.home_page_description_h2_colored {
    margin-top: 20px;
    font-family: var(--secondary-font);
    font-weight: 100;
    color: var(--text-color-primary-offset);
    font-size: 0.9rem;
}

.home_page_description_h2_colored {
    color: var(--secondary-color);
}

.contact_button_container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact_me_button {
    color: var(--secondary-color);
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    outline: 1px solid var(--secondary-color);
    font-family: var(--secondary-font);
    font-weight: 400;
    transition: 0.3s;
}



.contact_me_button:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    padding-left: 50px;
    padding-right: 50px;
}



/* Layer  */

.profile_image {
    width: 400px;
    height: 400px;
    margin-top: 100px;
    /* filter: hue-rotate(160deg) saturate(150%); */
}




.profile_bg_layer_1 {
    width: 500px;
    height: 500px;
    overflow: hidden;
    background-color: var(--secondary-color-secondary-extra-dark);
}

.profile_bg_layer_2 {
    width: 450px;
    height: 450px;
    background-color: var(--secondary-color-secondary-dark);
}

.profile_bg_layer_3 {
    width: 350px;
    height: 350px;
    background-color: var(--secondary-color-secondary-light);
}

.profile_bg_layer_4 {
    width: 300px;
    height: 300px;
    background-color: var(--secondary-color-secondary-double-extra-dark);
}



.profile_bg_layer_1,
.profile_bg_layer_2,
.profile_bg_layer_3,
.profile_bg_layer_4 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.profile_bg_layer_4 {
    perspective: 1000px;
}

.profile_image {
    transform-style: preserve-3d;
    transition: transform 0.3s ease;
}

.profile_image:hover {
    transform: rotateX(10deg) rotateY(10deg);
}


@media (max-width: 1200px) {
    .profile_bg_layer_1 {
        width: 400px;
        height: 400px;
        overflow: hidden;
    }

    .profile_bg_layer_2 {
        width: 350px;
        height: 350px;
    }

    .profile_bg_layer_3 {
        width: 250px;
        height: 250px;
    }

    .profile_bg_layer_4 {
        width: 200px;
        height: 200px;
    }

    .profile_image {
        width: 400px;
        height: 400px;

    }
}

@media (max-width: 780px) {
    .home_page_main {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .home_page_left {
        width: 90%;
    }

    .profile_image_parent {
        margin: auto;
    }

    .home_buttons_parent {
        display: none;
    }

    .home_page_right {
        width: 90%;
    }

    .home_page_name {
        font-size: 3rem;
    }

    .profile_bg_layer_1 {
        width: 300px;
        height: 300px;
    }

    .profile_bg_layer_2 {
        width: 250px;
        height: 250px;
    }

    .profile_bg_layer_3 {
        width: 150px;
        height: 150px;
    }

    .profile_bg_layer_4 {
        width: 100px;
        height: 100px;
    }

    .profile_image {
        width: 300px;
        height: 300px;
    }
}


/* cursor */
@keyframes blink {

    0%,
    100% {
        opacity: 0;
        /* Fade out at the start and end */
    }

    50% {
        opacity: 1;
        /* Fully visible in the middle */
    }
}

.continue_blink {
    animation: blink 0.3s infinite;
    /* Adjust the duration as needed */
}

@media (max-width: 400px) {
 


    

 

    .home_page_name {
        font-size: 3rem;
    }
    .home_page_right{
        display: none;
    }
    .profile_bg_layer_1 {
        width: 200px;
        height: 200px;
    }

    .profile_bg_layer_2 {
        width: 150px;
        height: 150px;
    }

    .profile_bg_layer_3 {
        width: 50px;
        height: 50px;
    }

    .profile_bg_layer_4 {
        width: 20px;
        height: 20px;
    }

    .profile_image {
        width: 300px;
        height: 300px;
    }
}