.projects_parent {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.projects_structure {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
}

.projects_content {
    width: 75%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.project_image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.4s;
    transform: scale(1);
}

.project_image:hover {
    transition: 0.4s;
    transform: scale(1.01);
}

.project_black_layer {
    width: calc(100% - 40px);
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(336deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background-color: #00000011;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
}

.project_black_layer:hover {
    transition: 0.4s;
    background: linear-gradient(336deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background-color: #000000d8;
}

.project_content_data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    transition: 0.4s;
}

.project_title {
    font-family: var(--primary-font);
    font-size: 3rem;
    color: var(--text-color-primary);
    transition: 0.4s;
}

.project_description {
    font-family: var(--secondary-font);
    color: var(--text-color-secondary);
    width: 60%;
}

.project_download_button {
    margin-top: 80px;
    font-family: var(--secondary-font);
    color: var(--secondary-color);
    background-color: var(--secondary-color);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project_download_button:hover {
    background-color: var(--secondary-color-secondary-light);
    transition: 0.4s;
}

.project_download_button_icon {
    margin-left: 5px;
    width: 16px;
    height: 16px;
}

.project_language_use,
.project_key_point {
    width: 60%;
    margin-top: 15px;
    color: var(--text-color-primary);
    transition: 0.4s;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    flex-wrap: wrap;
}


.project_language_use_div,
.project_key_point_div {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: var(--primary-color);
    margin-right: 12px;
    border-radius: 4px;
    transition: 0.4s;
    text-wrap: nowrap;
    margin-top: 5px;
    margin-bottom: 5px;
    color: var(--text-color-primary);
    font-family: var(--secondary-font);
    font-size: 0.8rem;
}

.project_key_point_div:hover {
    transition: 0.4s;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.project_language_use_div {
    background-color: var(--secondary-color);
    transition: 0.4s;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: var(--primary-color);
}

.project_language_use_div:hover {
    transition: 0.4s;
    background-color: var(--primary-color);
    color: var(--text-color-primary);
}

.project_content_data {
    /* transform: translateY(240px); */
    /* outline: 1px solid black; */
}

.projects_content:hover .project_content_data {
    transition: 0.4s;
    transform: translateY(0px);
    cursor: pointer;
}



@media (max-width:1500px) {
    .projects_content {
        width: 100%;
    }

    .project_content_data {
        transform: translateY(0px);
        cursor: pointer;
    }

    .project_project_links {
        margin-top: 40px;
    }
}

@media (max-width:650px) {
    .project_title {
        font-size: 2rem;
    }

    .project_description {
        width: 100%;
        font-size: 0.9rem;
    }

    .project_project_links {
        width: 100%;
        font-size: 0.9rem;
        margin-top: 20px;
    }

    .project_language_use,
    .project_key_point {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        color: var(--text-color-primary);
        font-size: 0.8rem;
        padding-left: 2px;
        padding-right: 2px;
    }

}

@media (max-width:500px) {
    .project_title {
        font-size: 1rem;
    }

    .project_description {
        font-size: 0.7rem;
    }

    .project_project_links {
        font-size: 0.7rem;
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .project_language_use.project_key_point {
        display: flex;
        flex-wrap: wrap;
        color: var(--text-color-primary);
        font-size: 0.7rem;
        text-wrap: nowrap;
        font-weight: lighter;
    }

    .project_key_point {
        display: flex;
        flex-wrap: wrap;
        color: var(--text-color-primary);
        font-size: 0.7rem;
        text-wrap: nowrap;
        font-weight: lighter;
    }

}