@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono);
:root {
    --primary-color: #0a192f;
    --primary-color-second: #112240;
    --secondary-color: #64ffda;
    --secondary-color-offset: #64ffdb8a;
    --secondary-color-secondary-light: #8efff1;
    --secondary-color-secondary-dark: #3fc7ba;
    --secondary-color-secondary-extra-dark: #64ffda;
    --secondary-color-secondary-double-extra-dark: #64ffda;

    --text-color-primary: #ffffff;
    --text-color-primary-offset: rgba(0, 255, 255, 0.705);
    --text-color-secondary: #64ffda;

    --primary-font: Raleway-ExtraBold;
    --secondary-font: 'Roboto Mono', monospace;

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 450px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.index-resume-div {
  /* width: 80vw; */
  /* height: 200vh; */
  /* background-color: black; */
  /* padding-top: 10vh; */
  /* padding-bottom: 10vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  color: var(--text-color-primary);
}

@font-face {
  font-family: Raleway-Thin;
  src: url(/static/media/Raleway-Thin.db8e7354.ttf);
}

@font-face {
  font-family: Raleway-ExtraLight;
  src: url(/static/media/Raleway-ExtraLight.550ad10f.ttf);
}

@font-face {
  font-family: Raleway-Light;
  src: url(/static/media/Raleway-Light.ed645c2b.ttf);
}

@font-face {
  font-family: Raleway-Regular;
  src: url(/static/media/Raleway-Regular.20d41940.ttf);
}

@font-face {
  font-family: Raleway-Medium;
  src: url(/static/media/Raleway-Medium.b952c3c8.ttf);
}

@font-face {
  font-family: Raleway-SemiBold;
  src: url(/static/media/Raleway-SemiBold.87641f99.ttf);
}

@font-face {
  font-family: Raleway-Bold;
  src: url(/static/media/Raleway-Bold.88079335.ttf);
}

@font-face {
  font-family: Raleway-ExtraBold;
  src: url(/static/media/Raleway-ExtraBold.27f7ef17.ttf);
}

@font-face {
  font-family: Raleway-Black;
  src: url(/static/media/Raleway-Black.35e0e2e7.ttf);
}



@font-face {
  font-family: Ubuntu-SemiBold;
  src: url(/static/media/Ubuntu-Bold.896a6021.ttf);
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url(/static/media/Ubuntu-Light.c9d68f61.ttf);
}

@font-face {
  font-family: Ubuntu-ExtraBold;
  src: url(/static/media/Ubuntu-Medium.d3c3b35e.ttf);
}

@font-face {
  font-family: Ubuntu-Black;
  src: url(/static/media/Ubuntu-Regular.84ea7c5c.ttf);
}



/* Roboto */
@font-face {
  font-family: RobotoMono-Black;
  src: url(/static/media/RobotoMono-Bold.90190d91.ttf);
}

@font-face {
  font-family: RobotoMono-Black;
  src: url(/static/media/RobotoMono-ExtraLight.642c61c3.ttf);
}

@font-face {
  font-family: RobotoMono-Black;
  src: url(/static/media/RobotoMono-Light.c9166464.ttf);
}

@font-face {
  font-family: RobotoMono-Black;
  src: url(/static/media/RobotoMono-Medium.0eaa3f45.ttf);
}

@font-face {
  font-family: RobotoMono-Black;
  src: url(/static/media/RobotoMono-Regular.5b04fdfe.ttf);
}

@font-face {
  font-family: RobotoMono-Black;
  src: url(/static/media/RobotoMono-SemiBold.eabbacb6.ttf);
}

@font-face {
  font-family: RobotoMono-Black;
  src: url(/static/media/RobotoMono-Thin.564755b4.ttf);
}


.index-center-align {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: make scrollbar transparent */
}

body {
  scrollbar-width: thin;
  -ms-overflow-style: none;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: var(--primary-color);
  /* padding-bottom: 50px; */
}


body {
  overflow-x: hidden;
  /* cursor: none; */
}

.custom_cursor {
  width: 36px;
  height: 36px;
  position: fixed;
  background-color: transparent;
  border-radius: 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
  z-index: 1000;
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);
}


.custom_cursor_chase {
  width: 24px;
  height: 24px;
  position: fixed;
  z-index: 9999;
  border-radius: 50%;
  border: 1px solid white;
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);
  width: 20px;
  height: 20px;
  background-color: #000000;
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  opacity: 0;
}

.char_name {
  transition: 0.3s;
  -webkit-user-select: none;
  user-select: none;
}

.char_name:hover {
  color: #E30022;
  transition: 0.3s;
}

html {
  scroll-behavior: smooth;
}
.header_main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}



.site_name {
    font-weight: 900;
    font-size: 2rem;
    font-family: monospace;
    color: var(--text-color-primary);
}


.site_route_buttons {
    font-weight: lighter;
    color: var(--text-color-primary);
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.route_button {
    font-size: 0.8rem;
    text-align: center;
    font-family: var(--secondary-font);
    color: var(--text-color-secondary);
}

.route_button:hover {
    transition: 0.3s;
    cursor: pointer;
}

.under_line {
    width: 0%;
    height: 2px;
    background-color: var(--secondary-color);
    transition: 0.3s;
}

.route_button:hover+.under_line {
    width: 100%;
    transition: 0.3s;
}

.route_button:hover+.custom_cursor {
    width: 20px;
    height: 20px;
}

.red_letter {
    color: var(--secondary-color);
}

.dark_mode_toggle {
    font-size: 24px;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    opacity: 1;
}



.dark_mode {
    color: #ffffff;
    /* Dark mode color */
}

.light_mode {
    color: #ffffff;
    /* Light mode color */
}


.dark_mode_toggle.fade-out {
    opacity: 0;
}


@media (max-width:780px) {
    .header_main {
        height: 3vh;
        display: none;
    }

    .site_route_buttons {
        display: none;
    }

    .site_name {
        width: 100vw;
        text-align: center;
        margin-left: 0;
    }
}
.home_page_main {
    display: flex;
    width: 100vw;
    /* padding-left: 5vw; */
    /* padding-right: 5vw; */
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
}

.home_page_left {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 50%;
    padding-left: 5vw;
}

.home_page_right {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 50%;
    padding-right: 5vw;
}

.home_buttons_parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    margin-left: 1vw;
}

.home_button {
    border: 2px solid var(--secondary-color);
    padding: 10px;
    border-radius: 50%;
    overflow: visible;
    transition: 0.3s;
    color: var(--secondary-color);
}


.contact_me_button:hover{
    cursor: pointer;
}

.home_button:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    border-color: var(--primary-color);
    color: var(--primary-color);
    transition: 0.3s;
}


.home_page_name {
    font-family: Raleway-ExtraBold;
    color: var(--text-color-primary);
    font-size: 6rem;
}

.home_page_description_h1 {
    font-family: var(--secondary-font);
    font-weight: 100;
    color: var(--text-color-primary);
    font-size: 2rem;
}

.home_page_description_h2,
.home_page_description_h2_colored {
    margin-top: 20px;
    font-family: var(--secondary-font);
    font-weight: 100;
    color: var(--text-color-primary-offset);
    font-size: 0.9rem;
}

.home_page_description_h2_colored {
    color: var(--secondary-color);
}

.contact_button_container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact_me_button {
    color: var(--secondary-color);
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    outline: 1px solid var(--secondary-color);
    font-family: var(--secondary-font);
    font-weight: 400;
    transition: 0.3s;
}



.contact_me_button:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    padding-left: 50px;
    padding-right: 50px;
}



/* Layer  */

.profile_image {
    width: 400px;
    height: 400px;
    margin-top: 100px;
    /* filter: hue-rotate(160deg) saturate(150%); */
}




.profile_bg_layer_1 {
    width: 500px;
    height: 500px;
    overflow: hidden;
    background-color: var(--secondary-color-secondary-extra-dark);
}

.profile_bg_layer_2 {
    width: 450px;
    height: 450px;
    background-color: var(--secondary-color-secondary-dark);
}

.profile_bg_layer_3 {
    width: 350px;
    height: 350px;
    background-color: var(--secondary-color-secondary-light);
}

.profile_bg_layer_4 {
    width: 300px;
    height: 300px;
    background-color: var(--secondary-color-secondary-double-extra-dark);
}



.profile_bg_layer_1,
.profile_bg_layer_2,
.profile_bg_layer_3,
.profile_bg_layer_4 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.profile_bg_layer_4 {
    -webkit-perspective: 1000px;
            perspective: 1000px;
}

.profile_image {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.profile_image:hover {
    -webkit-transform: rotateX(10deg) rotateY(10deg);
            transform: rotateX(10deg) rotateY(10deg);
}


@media (max-width: 1200px) {
    .profile_bg_layer_1 {
        width: 400px;
        height: 400px;
        overflow: hidden;
    }

    .profile_bg_layer_2 {
        width: 350px;
        height: 350px;
    }

    .profile_bg_layer_3 {
        width: 250px;
        height: 250px;
    }

    .profile_bg_layer_4 {
        width: 200px;
        height: 200px;
    }

    .profile_image {
        width: 400px;
        height: 400px;

    }
}

@media (max-width: 780px) {
    .home_page_main {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .home_page_left {
        width: 90%;
    }

    .profile_image_parent {
        margin: auto;
    }

    .home_buttons_parent {
        display: none;
    }

    .home_page_right {
        width: 90%;
    }

    .home_page_name {
        font-size: 3rem;
    }

    .profile_bg_layer_1 {
        width: 300px;
        height: 300px;
    }

    .profile_bg_layer_2 {
        width: 250px;
        height: 250px;
    }

    .profile_bg_layer_3 {
        width: 150px;
        height: 150px;
    }

    .profile_bg_layer_4 {
        width: 100px;
        height: 100px;
    }

    .profile_image {
        width: 300px;
        height: 300px;
    }
}


/* cursor */
@-webkit-keyframes blink {

    0%,
    100% {
        opacity: 0;
        /* Fade out at the start and end */
    }

    50% {
        opacity: 1;
        /* Fully visible in the middle */
    }
}
@keyframes blink {

    0%,
    100% {
        opacity: 0;
        /* Fade out at the start and end */
    }

    50% {
        opacity: 1;
        /* Fully visible in the middle */
    }
}

.continue_blink {
    -webkit-animation: blink 0.3s infinite;
            animation: blink 0.3s infinite;
    /* Adjust the duration as needed */
}

@media (max-width: 400px) {
 


    

 

    .home_page_name {
        font-size: 3rem;
    }
    .home_page_right{
        display: none;
    }
    .profile_bg_layer_1 {
        width: 200px;
        height: 200px;
    }

    .profile_bg_layer_2 {
        width: 150px;
        height: 150px;
    }

    .profile_bg_layer_3 {
        width: 50px;
        height: 50px;
    }

    .profile_bg_layer_4 {
        width: 20px;
        height: 20px;
    }

    .profile_image {
        width: 300px;
        height: 300px;
    }
}
.where_i_worked {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 50px;
    padding-bottom: 50px;
}


.where_i_worked_right {
    width: 30%;
}

.where_i_worked_left {
    width: 65%;
    height: 380px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    padding-right: 20px;
}

.where_i_worked_left_childs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.where_i_worked_right_buttons_parent {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    gap: 14px;
}

.where_i_worked_right_button {
    width: auto;
    background-color: var(--primary-color-second);
    padding: 30px;
    border-radius: 12px;
    border: 1px solid var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--text-color-primary);
}

.where_i_worked_right_button:hover {
    width: auto;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 30px;
    border-radius: 12px;
}

.where_i_work_company_name {
    font-family: Raleway-Bold;
}

.where_i_work_company_duration {
    font-family: var(--secondary-font);
    font-weight: 100;
    font-size: 0.9rem;
    color: var(--text-color-primary-offset);
}

.where_i_worked_right_button:hover .where_i_work_company_duration {
    color: var(--primary-color);
}




.where_i_worked_left_company_name {
    color: var(--text-color-primary);
    font-family: var(--primary-font);
    font-size: 3rem;
    font-weight: bolder;
}

.where_i_worked_left_company_duration {
    color: var(--text-color-primary);
    font-family: var(--secondary-font);
    font-size: 1rem;
    margin-top: 10px;
}

.where_i_worked_left_company_duration_in_description {
    color: var(--text-color-primary-offset);
    font-family: var(--secondary-font);
    font-weight: 100;
    font-size: 0.9rem;
    margin-top: 5px;
}

.key_work_parent {
    margin-top: 20px;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    grid-gap: 14px;
    gap: 14px;
}

.key_work_data {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: lighter;
    background-color: var(--secondary-color);
    padding: 4px 15px;
    border-radius: 50px;
    white-space: nowrap;
}


.where_i_worked_left {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

}

.where_i_worked_left.fade-out {
    opacity: 0;
}




@media (max-width:900px) {

    .where_i_worked_right_buttons_parent {
        grid-gap: 10px;
        gap: 10px;
    }

    .where_i_worked {
        flex-direction: column;
    }

    .where_i_worked_left {
        width: 90vw;
        height: auto;
        margin-left: 0;
        margin-right: 0;
        padding-top: 30px;
        padding-bottom: 30px;
        width: calc(90vw - 40px);
        margin-top: 10px;
    }

    .where_i_worked_right {
        width: 90vw;
        margin-right: 0;
        margin-left: 0;
    }

    .where_i_worked_left_company_duration {
        font-size: 1rem;
    }
}
.framework_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-wrap: wrap;
}

.framework_parent {
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--text-color-primary);
    width: 17%;
    min-width: 250px;
    height: 300px;
    background-color: var(--primary-color-second);
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* transition: 0.3s; */
    -webkit-transform: scale(1);
            transform: scale(1)
}



.framework_icon {
    width: 60px;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.framework_name {
    margin-top: 24px;
    font-family: monospace;
    color: var(--text-color-primary);
    font-size: 1.5rem;
    height: 40px;
}

.framework_description {
    color: var(--text-color-primary-offset);
    margin-top: 16px;
    font-family: var(--secondary-font);
    font-size: 0.7rem;
    height: 200px;
}

.framework_language {
    margin-top: 4px;
    font-family: var(--secondary-font);
    font-weight: lighter;
    font-size: 0.8rem;
    color: var(--text-color-primary-offset);
}

.framework_parent:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.framework_parent:hover .framework_description {
    color: var(--primary-color);
}

.framework_parent:hover .framework_language {
    color: var(--primary-color);
}

@media (max-width:900px) {
    .framework_container {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .framework_parent {

        width: calc(100% - 100px);
        flex-direction: column;
        grid-gap: 50px;
        gap: 50px;
    }
}

@media (max-width:500px) {
    .framework_parent {
        height: auto;
    }

    .framework_name {
        margin-top: 0px;
    }

    .framework_description {
        margin-top: 0px;
        height: auto;
    }

    .framework_language {
        margin-top: 0px;
        height: auto;
    }
}
/* Style the loader container */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color); /* Dark, semi-transparent background */
    z-index: 999; /* Ensure it's above other content */
  }
  
  /* Style the loading progress text */
  .loading-progress {
    color: #fff; /* White text for visibility */
    font-family: sans-serif;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
  }
  .loading-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Style the loader animation */
  .loader {
    width: 25px;
    height: 25px;
    border: 5px solid #fff; /* White border for contrast */
    border-radius: 50%;
    -webkit-animation: rotate 1s linear infinite;
            animation: rotate 1s linear infinite;
  }
  
  @-webkit-keyframes rotate {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes rotate {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
.Resume-div-main-parent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--primary-color-second);
}

.Resume-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    overflow: hidden;

}

.Resume-title-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content: left;
}


.Resume-profile-Description-main {
    color: white;
    display: flex;
    flex-direction: column;
}

.Resume-Description-Name {
    font-size: 3rem;
    font-family: var(--primary-font)
}

.Resume-Description-Post {
    font-size: 1rem;
    font-family: var(--secondary-font);
    white-space: nowrap;
    color: #53cfd8;
}

.Resume-Description-About {
    padding-top: 5px;
    font-family: var(--secondary-font);
    font-size: 0.8rem;
}

.Resume-div-divideLine {
    width: 90vw;
    height: 0.5px;
    opacity: 0.3;
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 40px;
    margin-bottom: 40px;
}

.Resume-div-details-title-text {
    font-size: 1rem;
    font-family: var(--secondary-font);
    white-space: nowrap;
    color: #53cfd8;
}

.Resume-div-second-container-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Resume-div-second-container-1 {
    /* width: 40vw; */
    /* outline: 1px solid rebeccapurple; */
    /* justify-content: space-around; */
}

.Resume-div-second-container-2 {}

.Resume-div-second-container-3 {
    width: 40vw;
}

.Resume-div-second-container-title {
    /* font-family: Raleway-Bold; */
    font-family: var(--secondary-font);
    color: #53cfd8;
    padding-bottom: 40px;
}

.Resume-div-second-container-1-description {
    color: black;
}

.Resume-div-second-container-1-contact-div-contact-info {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
}

.Resume-div-second-container-1-contact-div-contact-info-icon {
    width: 25px;
    height: 25px;
}

.Resume-div-second-container-1-contact-div-contact-info-detail {
    padding-left: 0.5vw;
    color: white;
    font-family: var(--secondary-font);
    display: flex;
    align-items: center;
    font-size: 0.8rem
}

.Resume-div-work-experience-content {
    display: flex;
    flex-direction: row;
    /* width: auto; */
}

.Resume-div-dot {
    margin-top: 3vh;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 14px solid #ffffff;
    border-bottom: 7px solid transparent;
    margin-right: 1vw;
    background-color: #53cfd8;
    border-radius: 50%;
}

.Resume-div-details-title {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    color: white;
    font-size: 0.9rem;
    font-family: var(--secondary-font);
}

.Resume-div-details-date {
    padding-top: 1px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.573);
    font-family: var(--secondary-font);
}

.Resume-div-details-description {
    padding-top: 1px;
    color: rgba(255, 255, 255, 0.345);
    font-family: var(--secondary-font);
    font-size: 0.7rem;
    /* padding-right: 10vw; */
}

@media (max-width: 750px) {

    .Resume-title-div {
        flex-direction: column;
        align-items: center;
    }

    .Resume-profile-Description-main {
        padding-top: 3vh;
        padding-left: 0vw;
        padding-right: 0vw;
        align-items: center;
        text-align: center;
    }

    .Resume-div-second-container-1 {
        width: 70vw;
    }

    .Resume-div-second-container-2 {}

    .Resume-div-second-container-3 {
        width: 70vw;
    }

    .Resume-div-second-container-main {
        display: flex;
        flex-direction: column;
    }

}
.projects_parent {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 5rem;
    gap: 5rem;
}

.projects_structure {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
}

.projects_content {
    width: 75%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.project_image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.4s;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.project_image:hover {
    transition: 0.4s;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}

.project_black_layer {
    width: calc(100% - 40px);
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(336deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background-color: #00000011;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
}

.project_black_layer:hover {
    transition: 0.4s;
    background: linear-gradient(336deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background-color: #000000d8;
}

.project_content_data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    transition: 0.4s;
}

.project_title {
    font-family: var(--primary-font);
    font-size: 3rem;
    color: var(--text-color-primary);
    transition: 0.4s;
}

.project_description {
    font-family: var(--secondary-font);
    color: var(--text-color-secondary);
    width: 60%;
}

.project_download_button {
    margin-top: 80px;
    font-family: var(--secondary-font);
    color: var(--secondary-color);
    background-color: var(--secondary-color);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project_download_button:hover {
    background-color: var(--secondary-color-secondary-light);
    transition: 0.4s;
}

.project_download_button_icon {
    margin-left: 5px;
    width: 16px;
    height: 16px;
}

.project_language_use,
.project_key_point {
    width: 60%;
    margin-top: 15px;
    color: var(--text-color-primary);
    transition: 0.4s;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    flex-wrap: wrap;
}


.project_language_use_div,
.project_key_point_div {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: var(--primary-color);
    margin-right: 12px;
    border-radius: 4px;
    transition: 0.4s;
    text-wrap: nowrap;
    margin-top: 5px;
    margin-bottom: 5px;
    color: var(--text-color-primary);
    font-family: var(--secondary-font);
    font-size: 0.8rem;
}

.project_key_point_div:hover {
    transition: 0.4s;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.project_language_use_div {
    background-color: var(--secondary-color);
    transition: 0.4s;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: var(--primary-color);
}

.project_language_use_div:hover {
    transition: 0.4s;
    background-color: var(--primary-color);
    color: var(--text-color-primary);
}

.project_content_data {
    /* transform: translateY(240px); */
    /* outline: 1px solid black; */
}

.projects_content:hover .project_content_data {
    transition: 0.4s;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    cursor: pointer;
}



@media (max-width:1500px) {
    .projects_content {
        width: 100%;
    }

    .project_content_data {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        cursor: pointer;
    }

    .project_project_links {
        margin-top: 40px;
    }
}

@media (max-width:650px) {
    .project_title {
        font-size: 2rem;
    }

    .project_description {
        width: 100%;
        font-size: 0.9rem;
    }

    .project_project_links {
        width: 100%;
        font-size: 0.9rem;
        margin-top: 20px;
    }

    .project_language_use,
    .project_key_point {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        color: var(--text-color-primary);
        font-size: 0.8rem;
        padding-left: 2px;
        padding-right: 2px;
    }

}

@media (max-width:500px) {
    .project_title {
        font-size: 1rem;
    }

    .project_description {
        font-size: 0.7rem;
    }

    .project_project_links {
        font-size: 0.7rem;
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .project_language_use.project_key_point {
        display: flex;
        flex-wrap: wrap;
        color: var(--text-color-primary);
        font-size: 0.7rem;
        text-wrap: nowrap;
        font-weight: lighter;
    }

    .project_key_point {
        display: flex;
        flex-wrap: wrap;
        color: var(--text-color-primary);
        font-size: 0.7rem;
        text-wrap: nowrap;
        font-weight: lighter;
    }

}
.footer_parent {
    width: 100vw;
    text-align: center;
    font-family: var(--secondary-font);
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.footer_parent:hover {
    cursor: pointer;
}

.footer_parent,
.portfolio_source_code {
    padding-left: 50px;
    padding-right: 50px;
}

.portfolio_old_version {
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: underline;
}

@media (max-width:600px) {
    .footer_parent {
        display: flex;
    }

    .footer_parent,
    .portfolio_source_code {
        padding-left: 7px;
        padding-right: 7px;
    }
}
.profile_parent {
    padding-top: 20px;
    padding-bottom: 20px;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--primary-color-second);
    border-radius: 12px;
}

.profile_title {
    font-size: 2rem;
    color: var(--text-color-primary);
    font-family: var(--primary-font);
}

.profile_description {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 0.8rem;
    color: var(--text-color-secondary);
    font-family: var(--secondary-font);
}

.profile_other_hobby {
    font-size: 0.8rem;
    color: var(--text-color-secondary);
    font-family: var(--secondary-font);
}
    /* //!Code Should be removed on Production  */
    .sanju_cv_parent {
        /* background-color: var(--primary-color); */
    }


    /* //!Code Should be removed on Production  */

    .sanju_cv_parent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .sanju_cv_container {
        width: calc(95% - 40px);
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 50px;
        padding-bottom: 50px;

        border-radius: 12px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }

    .sanju_cv_title_parent {
        color: var(--text-color-primary);
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .sanju_cv_title {
        font-family: var(--primary-font);
        font-size: 3rem;
    }

    .sanju_cv_description {
        font-family: var(--secondary-font);
        font-weight: 100;
        font-size: 0.9rem;
        width: 80%;

    }



    .sanju_cv_other_content_parent {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .sanju_cv_other_content_icon {
        width: 24px;
        height: 24px;
        padding: 10px;
    }

    .sanju_cv_other_content_value {
        font-family: var(--secondary-font);
        font-size: 0.9rem;
    }

    @media (max-width:800px) {
        .sanju_cv_container {
            margin: 0;
        }

        .sanju_cv_title_parent {
            flex-direction: column;
        }

        .sanju_cv_description {
            width: 100%;
        }

        .sanju_cv_other_content_icon {
            width: 24px;
            height: 24px;
            padding: 10px;
            padding-left: 0;
        }

        .sanju_cv_other_contact_div {
            padding-top: 40px;
        }

        .sanju_cv_other_content_value {
            font-family: var(--secondary-font);
            font-size: 0.8rem;
        }

    }



    .sanju_cv_draw_line {
        margin-top: 35px;
        width: 100%;
        height: 0.8px;
        background-color: #ffffff82;
    }

    .sanju_cv_data_div_parent {
        width: 100%;
        padding-top: 50px;
    }

    .sanju_cv_data_div {
        width: 100%;
        font-family: var(--primary-font);
        font-size: 2rem;
        color: var(--text-color-primary);
    }

    /* Professional Experience */
    .sanju_cv_highlight_text {
        font-weight: 200;
        font-size: 0.9rem;
    }

    .sanju_cv_professional_experience_company_details {
        margin-top: 20px;
    }

    .sanju_cv_professional_experience_company_name,
    .sanju_cv_professional_experience_company_designation,
    .sanju_cv_professional_experience_company_location,
    .sanju_cv_professional_experience_company_duration,
    .sanju_cv_professional_experience_company_key_points {
        font-family: var(--secondary-font);
        font-size: 0.7rem;
        font-weight: 100;
        color: var(--text-color-primary);
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }

    .sanju_cv_professional_experience_company_name {
        font-size: 1.2rem;
        font-family: var(--primary-font);

    }

    .sanju_cv_professional_experience_company_key_points {
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }

    /* Project Created */
    .sanju_cv_project_worked_div_title {
        font-family: var(--primary-font);
    }

    .sanju_cv_project_worked_div {
        font-family: var(--secondary-font);
        font-size: 0.8rem;
        color: var(--text-color-primary);
        font-weight: lighter;
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }

  
    .sanju_cv_project_worked_div_description {
        padding-top: 10px;
        padding-bottom: 10px;
    }
