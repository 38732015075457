:root {
    --primary-color: #0a192f;
    --primary-color-second: #112240;
    --secondary-color: #64ffda;
    --secondary-color-offset: #64ffdb8a;
    --secondary-color-secondary-light: #8efff1;
    --secondary-color-secondary-dark: #3fc7ba;
    --secondary-color-secondary-extra-dark: #64ffda;
    --secondary-color-secondary-double-extra-dark: #64ffda;

    --text-color-primary: #ffffff;
    --text-color-primary-offset: rgba(0, 255, 255, 0.705);
    --text-color-secondary: #64ffda;

    --primary-font: Raleway-ExtraBold;
    --secondary-font: 'Roboto Mono', monospace;

}