    /* //!Code Should be removed on Production  */
    .sanju_cv_parent {
        /* background-color: var(--primary-color); */
    }


    /* //!Code Should be removed on Production  */

    .sanju_cv_parent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .sanju_cv_container {
        width: calc(95% - 40px);
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 50px;
        padding-bottom: 50px;

        border-radius: 12px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }

    .sanju_cv_title_parent {
        color: var(--text-color-primary);
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .sanju_cv_title {
        font-family: var(--primary-font);
        font-size: 3rem;
    }

    .sanju_cv_description {
        font-family: var(--secondary-font);
        font-weight: 100;
        font-size: 0.9rem;
        width: 80%;

    }



    .sanju_cv_other_content_parent {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .sanju_cv_other_content_icon {
        width: 24px;
        height: 24px;
        padding: 10px;
    }

    .sanju_cv_other_content_value {
        font-family: var(--secondary-font);
        font-size: 0.9rem;
    }

    @media (max-width:800px) {
        .sanju_cv_container {
            margin: 0;
        }

        .sanju_cv_title_parent {
            flex-direction: column;
        }

        .sanju_cv_description {
            width: 100%;
        }

        .sanju_cv_other_content_icon {
            width: 24px;
            height: 24px;
            padding: 10px;
            padding-left: 0;
        }

        .sanju_cv_other_contact_div {
            padding-top: 40px;
        }

        .sanju_cv_other_content_value {
            font-family: var(--secondary-font);
            font-size: 0.8rem;
        }

    }



    .sanju_cv_draw_line {
        margin-top: 35px;
        width: 100%;
        height: 0.8px;
        background-color: #ffffff82;
    }

    .sanju_cv_data_div_parent {
        width: 100%;
        padding-top: 50px;
    }

    .sanju_cv_data_div {
        width: 100%;
        font-family: var(--primary-font);
        font-size: 2rem;
        color: var(--text-color-primary);
    }

    /* Professional Experience */
    .sanju_cv_highlight_text {
        font-weight: 200;
        font-size: 0.9rem;
    }

    .sanju_cv_professional_experience_company_details {
        margin-top: 20px;
    }

    .sanju_cv_professional_experience_company_name,
    .sanju_cv_professional_experience_company_designation,
    .sanju_cv_professional_experience_company_location,
    .sanju_cv_professional_experience_company_duration,
    .sanju_cv_professional_experience_company_key_points {
        font-family: var(--secondary-font);
        font-size: 0.7rem;
        font-weight: 100;
        color: var(--text-color-primary);
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }

    .sanju_cv_professional_experience_company_name {
        font-size: 1.2rem;
        font-family: var(--primary-font);

    }

    .sanju_cv_professional_experience_company_key_points {
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }

    /* Project Created */
    .sanju_cv_project_worked_div_title {
        font-family: var(--primary-font);
    }

    .sanju_cv_project_worked_div {
        font-family: var(--secondary-font);
        font-size: 0.8rem;
        color: var(--text-color-primary);
        font-weight: lighter;
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }

  
    .sanju_cv_project_worked_div_description {
        padding-top: 10px;
        padding-bottom: 10px;
    }