.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: var(--primary-color);
  /* padding-bottom: 50px; */
}


body {
  overflow-x: hidden;
  /* cursor: none; */
}

.custom_cursor {
  width: 36px;
  height: 36px;
  position: fixed;
  background-color: transparent;
  border-radius: 50%;
  filter: invert(100%);
  z-index: 1000;
  backdrop-filter: blur(0px);
}


.custom_cursor_chase {
  width: 24px;
  height: 24px;
  position: fixed;
  z-index: 9999;
  border-radius: 50%;
  border: 1px solid white;
  backdrop-filter: blur(0px);
  width: 20px;
  height: 20px;
  background-color: #000000;
  transform-origin: center;
  transition: transform 0.2s ease-out;
  opacity: 0;
}

.char_name {
  transition: 0.3s;
  -webkit-user-select: none;
  user-select: none;
}

.char_name:hover {
  color: #E30022;
  transition: 0.3s;
}

html {
  scroll-behavior: smooth;
}