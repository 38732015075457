/* Style the loader container */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color); /* Dark, semi-transparent background */
    z-index: 999; /* Ensure it's above other content */
  }
  
  /* Style the loading progress text */
  .loading-progress {
    color: #fff; /* White text for visibility */
    font-family: sans-serif;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
  }
  .loading-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Style the loader animation */
  .loader {
    width: 25px;
    height: 25px;
    border: 5px solid #fff; /* White border for contrast */
    border-radius: 50%;
    animation: rotate 1s linear infinite;
  }
  
  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  