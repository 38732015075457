.framework_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-wrap: wrap;
}

.framework_parent {
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--text-color-primary);
    width: 17%;
    min-width: 250px;
    height: 300px;
    background-color: var(--primary-color-second);
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* transition: 0.3s; */
    transform: scale(1)
}



.framework_icon {
    width: 60px;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.framework_name {
    margin-top: 24px;
    font-family: monospace;
    color: var(--text-color-primary);
    font-size: 1.5rem;
    height: 40px;
}

.framework_description {
    color: var(--text-color-primary-offset);
    margin-top: 16px;
    font-family: var(--secondary-font);
    font-size: 0.7rem;
    height: 200px;
}

.framework_language {
    margin-top: 4px;
    font-family: var(--secondary-font);
    font-weight: lighter;
    font-size: 0.8rem;
    color: var(--text-color-primary-offset);
}

.framework_parent:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.framework_parent:hover .framework_description {
    color: var(--primary-color);
}

.framework_parent:hover .framework_language {
    color: var(--primary-color);
}

@media (max-width:900px) {
    .framework_container {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .framework_parent {

        width: calc(100% - 100px);
        flex-direction: column;
        gap: 50px;
    }
}

@media (max-width:500px) {
    .framework_parent {
        height: auto;
    }

    .framework_name {
        margin-top: 0px;
    }

    .framework_description {
        margin-top: 0px;
        height: auto;
    }

    .framework_language {
        margin-top: 0px;
        height: auto;
    }
}