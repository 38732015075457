.where_i_worked {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 50px;
    padding-bottom: 50px;
}


.where_i_worked_right {
    width: 30%;
}

.where_i_worked_left {
    width: 65%;
    height: 380px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    padding-right: 20px;
}

.where_i_worked_left_childs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.where_i_worked_right_buttons_parent {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.where_i_worked_right_button {
    width: auto;
    background-color: var(--primary-color-second);
    padding: 30px;
    border-radius: 12px;
    border: 1px solid var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--text-color-primary);
}

.where_i_worked_right_button:hover {
    width: auto;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 30px;
    border-radius: 12px;
}

.where_i_work_company_name {
    font-family: Raleway-Bold;
}

.where_i_work_company_duration {
    font-family: var(--secondary-font);
    font-weight: 100;
    font-size: 0.9rem;
    color: var(--text-color-primary-offset);
}

.where_i_worked_right_button:hover .where_i_work_company_duration {
    color: var(--primary-color);
}




.where_i_worked_left_company_name {
    color: var(--text-color-primary);
    font-family: var(--primary-font);
    font-size: 3rem;
    font-weight: bolder;
}

.where_i_worked_left_company_duration {
    color: var(--text-color-primary);
    font-family: var(--secondary-font);
    font-size: 1rem;
    margin-top: 10px;
}

.where_i_worked_left_company_duration_in_description {
    color: var(--text-color-primary-offset);
    font-family: var(--secondary-font);
    font-weight: 100;
    font-size: 0.9rem;
    margin-top: 5px;
}

.key_work_parent {
    margin-top: 20px;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 14px;
}

.key_work_data {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: lighter;
    background-color: var(--secondary-color);
    padding: 4px 15px;
    border-radius: 50px;
    white-space: nowrap;
}


.where_i_worked_left {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

}

.where_i_worked_left.fade-out {
    opacity: 0;
}




@media (max-width:900px) {

    .where_i_worked_right_buttons_parent {
        gap: 10px;
    }

    .where_i_worked {
        flex-direction: column;
    }

    .where_i_worked_left {
        width: 90vw;
        height: auto;
        margin-left: 0;
        margin-right: 0;
        padding-top: 30px;
        padding-bottom: 30px;
        width: calc(90vw - 40px);
        margin-top: 10px;
    }

    .where_i_worked_right {
        width: 90vw;
        margin-right: 0;
        margin-left: 0;
    }

    .where_i_worked_left_company_duration {
        font-size: 1rem;
    }
}