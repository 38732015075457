.header_main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}



.site_name {
    font-weight: 900;
    font-size: 2rem;
    font-family: monospace;
    color: var(--text-color-primary);
}


.site_route_buttons {
    font-weight: lighter;
    color: var(--text-color-primary);
    display: flex;
    gap: 20px;
}

.route_button {
    font-size: 0.8rem;
    text-align: center;
    font-family: var(--secondary-font);
    color: var(--text-color-secondary);
}

.route_button:hover {
    transition: 0.3s;
    cursor: pointer;
}

.under_line {
    width: 0%;
    height: 2px;
    background-color: var(--secondary-color);
    transition: 0.3s;
}

.route_button:hover+.under_line {
    width: 100%;
    transition: 0.3s;
}

.route_button:hover+.custom_cursor {
    width: 20px;
    height: 20px;
}

.red_letter {
    color: var(--secondary-color);
}

.dark_mode_toggle {
    font-size: 24px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
}



.dark_mode {
    color: #ffffff;
    /* Dark mode color */
}

.light_mode {
    color: #ffffff;
    /* Light mode color */
}


.dark_mode_toggle.fade-out {
    opacity: 0;
}


@media (max-width:780px) {
    .header_main {
        height: 3vh;
        display: none;
    }

    .site_route_buttons {
        display: none;
    }

    .site_name {
        width: 100vw;
        text-align: center;
        margin-left: 0;
    }
}